import React, { useEffect } from 'react';
import { Dropdown, Row, Col } from 'react-bootstrap';
import i18n from '../../../i18n';
import '../../css/MainLayout.css';
import '../../css/Switch.css';
import '../../css/settings_page/SettingsPageNotification.css';
import '../../css/settings_page/SettingsPageSystemConfig.css';

const SettingsPageSystemConfig = () => {
  useEffect(() => {
  }, []);

  // 語言選項
  const languageOptions = {
    'zh-TW': '中文(繁體)',
    'zh-CN': '中文(简体)',
    'en': 'English',
    'jp': '日本語',
  };

  const handleLanguageChange = (lang) => {
    localStorage.setItem('language', lang);
    window.location.reload(false);
  };

  return (
    <div className="full-page-container">
      {/* 版本號和語言切換 */}
      <Row className="mb-4">
        <Col className="text-white text-center version">
          Version : Fleet v1.1.1
        </Col>
      </Row>
      <Row className="align-items-center mb-4">
        <Col xs="auto" className="text-white">
          {i18n.t('language_settings')}
        </Col>
        <Col>
          <Dropdown>
            <Dropdown.Toggle
              variant="secondary"
              id="language-dropdown"
              className="text-white"
            >
              {languageOptions[localStorage.getItem('language') || 'en']}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {Object.entries(languageOptions).map(([lang, label]) => (
                <Dropdown.Item
                  key={lang}
                  onClick={() => handleLanguageChange(lang)}
                  className="text-white"
                >
                  {label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </div>
  );
};

export default SettingsPageSystemConfig;
