// Import the functions you need from the SDKs you need
// import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import firebase from 'firebase/app';

import { getAuth, onAuthStateChanged } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDs18vl2gC9OWa9eGTboME5Ek-hFdX0NM0',
  authDomain: 'mindtronicai-omni.firebaseapp.com',
  projectId: 'mindtronicai-omni',
  storageBucket: 'mindtronicai-omni.appspot.com',
  messagingSenderId: '239328127680',
  appId: '1:239328127680:web:7046a982e09264a61cdb25',
  measurementId: 'G-JV3E75513Y',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

async function FetchAccessToken() {
  const auth = getAuth();

  // Check if the current user is already available
  if (auth.currentUser) {
      return auth.currentUser.getIdToken();  // This will automatically refresh the token if needed
  }

  // Wait for the authentication state to be initialized
  const user = await new Promise((resolve, reject) => {
      onAuthStateChanged(auth, (user) => {
          if (user) {
              resolve(user);
          } else {
              reject(new Error('No user logged in'));
          }
      });
  });

  return user.getIdToken(); // Since user is not null here, we can directly call getIdToken
}


export { app, auth, FetchAccessToken };
