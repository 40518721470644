import Config from './config.json';

let LoginInfo = {
  account: '',
  userId: '',
  companyId: '',
  loginStatus: false,
};

const DemoDate = (type) => {
  if (Config.DEMO_DATE_LIST == null) {
    return Config.DEMO_DATE;
  }

  switch (type) {
    case 'driver_event_bar':
      return Config.DEMO_DATE_LIST.DRIVER_EVENT_BAR;
    case 'driver_avg':
      return Config.DEMO_DATE_LIST.DRIVER_V007_AVG;
    case 'driver_events':
      return Config.DEMO_DATE_LIST.DRIVER_EVENTS_V007;
    case 'vehicle_realtime':
      return Config.DEMO_DATE_LIST.VEHICLE_V003_REALTIME;
    case 'vehicle_avg':
      return Config.DEMO_DATE_LIST.VEHICLE_V003_AVG;
    case 'vehicle_events':
      return Config.DEMO_DATE_LIST.VEHICLE_EVENTS_V007;
    case 'driver_score':
      return Config.DEMO_DATE_LIST.DRIVER_SCORE;
    case 'analysis_economy':
      return Config.DEMO_DATE_LIST.ANALYSIS_ECONOMY;
    case 'events':
      return Config.DEMO_DATE_LIST.EVENTS;
    default:
      return Config.DEMO_DATE;
  }
};

const demoAccounts = Config.DEMO_ACCOUNTS;

const IsDemoAccount = () => demoAccounts.includes(LoginInfo.account);

function SetLoginInfo(info) {
  LoginInfo = info;
}

function GetLoginInfo() {
  return LoginInfo;
}

export {
  GetLoginInfo, SetLoginInfo, DemoDate, IsDemoAccount,
};
