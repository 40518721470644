import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Card, Spinner, Collapse,
} from 'react-bootstrap';
import i18n from '../../../i18n';
import { FleetDeviceAPIUrl, FleetDriverAPIUrl, FleetFormalAPIUrl } from '../../GlobalDefine';
import SearchImg from '../../../textures/materials/icn_search.png';
import '../../css/fleet_page/FleetPageSideBar.css';
import '../../css/ThemeVariable.css';
import avastar from '../../../textures/default_driver.png';

const FleetPageSideBar = ({
  currentDeviceId, driverLocations, onDeviceSelect, fleetStatus, vehicleData, driverData,
}) => {
  const [driverIcons, setDriverIcons] = useState([]); // Add a state to store driver icons
  const [search, setSearch] = useState('');
  const [currentDevice, setCurrentDevice] = useState(currentDeviceId);
  const [isCollapsed, setIsCollapsed] = useState(false); // State to manage sidebar collapse
  const [dataReceive, setDataReceive] = useState(false);

  useEffect(() => {
    // getDriverIcons(); // Fetch driver icons when the component mounts
    if (fleetStatus && fleetStatus.length > 0) {
      setDataReceive(true);
    }
  },
  [fleetStatus, vehicleData, driverData]);

  useEffect(() => {
    if (currentDeviceId !== currentDevice) {
      setCurrentDevice(currentDeviceId);
    }
  }, [currentDeviceId]);

  useEffect(() => {
    setDriverIcons(driverLocations);
  }, [driverLocations]);

  const mergeData = () => fleetStatus.map((fleetItem) => {
    // Find matching vehicle data by device_id
    const matchingVehicle = vehicleData.find(
      (vehicle) => vehicle.device_id === fleetItem.device_id,
    );

    // Find matching driver data by driver_id
    const matchingDriver = driverData.find(
      (driver) => driver.driver_id === fleetItem.driver_id,
    );

    // Return the merged object
    return {
      ...fleetItem,
      car_num: matchingVehicle ? matchingVehicle.car_num : null, // Add car_num from vehicle data
      last_location: matchingVehicle ? matchingVehicle.last_location : null, // Add last_location
      geocoding: matchingVehicle ? matchingVehicle.geocoding : null, // Add geocoding
      driver_name: matchingDriver ? matchingDriver.name : null, // Add driver name
      driver_image_path: matchingDriver ? matchingDriver.image_path : null, // Add driver image path
    };
  });

  const getSearchResult = () => {
    const mergedData = mergeData(fleetStatus, vehicleData, driverData);
    let sortResult = [...mergedData];
    sortResult = sortResult.sort((a, b) => (a.car_num > b.car_num ? 1 : -1));
    const filteredResult = sortResult.sort((a, b) => {
      const now = moment();
      const aTime = moment(a.created_at);
      const bTime = moment(b.created_at);
      if (now.diff(aTime, 'minutes') < 1 && now.diff(bTime, 'minutes') < 1) {
        return 0;
      }
      if (now.diff(aTime, 'minutes') >= 1 && now.diff(bTime, 'minutes') >= 1) {
        return 0;
      }
      if (now.diff(bTime, 'minutes') < 1) {
        return 1;
      }
      return -1;
    });

    return filteredResult.filter(
      (device) => device.device_id.toLowerCase().indexOf(search.toLowerCase()) !== -1
        || device.car_num.toLowerCase().indexOf(search.toLowerCase()) !== -1,
    );
  };

  const handleDeviceClick = (device) => {
    setCurrentDevice(device.device_id);
    onDeviceSelect(device);
  };

  const renderDevice = (device) => {
    // If driverIcon exists, use its values; otherwise, use default values
    const driver_image_path = device.driver_image_path ? device.driver_image_path : 'no data';
    const driver_name = device.driver_name ? device.driver_name : 'no data';
    const url = FleetFormalAPIUrl.slice(0, -1) + driver_image_path;
    if (!device.car_num) {
      device.car_num = '';
    }

    // Determine point color based on conditions
    let isRunning = false;
    let pointColor;
    if (moment().diff(moment(device.created_at), 'minutes') >= 1) {
      pointColor = 'gray';
    } else if (device.idle) {
      pointColor = 'yellow';
    } else if (moment().diff(moment(device.created_at), 'minutes') < 1) {
      isRunning = true;
      pointColor = 'green';
    } else {
      pointColor = 'gray';
    }

    return (
      <div
        key={`${device.device_id}_link_fleet`}
        onClick={() => handleDeviceClick({
          ...device,
          driver_image_path,
          driver_name,
        })}
        className="device-card"
      >
        <div className="device-card-content">
          {/* First Row: Status Point, Car Number, Driver Info */}
          <div className="device-info-row row-left-align">
            <div className="device-info-item">
              {/* Status Point */}
              <div
                className="status-point"
                style={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor: pointColor,
                  marginRight: '10px',
                }}
              />
              <div className="car-number">
                {device.car_num.length > 10 ? `${device.car_num.substring(0, 10)}...` : device.car_num}
              </div>
            </div>
            <div className="driver-info">
              <img
                alt="driver-icon"
                src={url}
                className="driver-icon"
                onError={(e) => { e.target.onerror = null; e.target.src = avastar; }}
              />
              <div className="driver-name">
                {driver_name.length > 10 ? `${driver_name.substring(0, 9)}...` : driver_name}
              </div>
            </div>
          </div>

          {/* Second Row: Geocoding, Speed, and Last On Duty Time */}
          <div className="device-info-row row-padding">
            <div className="geocoding">
              {device.geocoding}
            </div>
            <div className="right-aligned-item">
              <div className="speed-info">
                {(device.speed && isRunning) ? `${device.speed} km/h` : '0 km/h'}
              </div>
              <div className="last-on-duty-time">
                {moment(device.created_at).format('MM/DD HH:mm')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const searchResult = getSearchResult();

  return (
    <>
      <div className="sidebar top-simple-container fleetpage">
        <img className="sidebar search-icon" alt="" src={SearchImg} />
        <input
          className="sidebar searchbar"
          placeholder={i18n.t('search_bar_vehicle')}
          onChange={(e) => setSearch(e.target.value)}
          id="searchbar-placeholder-style"
          value={search}
        />
      </div>

      <Collapse in={!isCollapsed}>
        <div>
          <div id="fade-in" className="sidebar fleet-info-container">
            {!dataReceive && (
              <div className="spinner-container">
                <Spinner className="spinner" animation="border" />
              </div>
            )}
            {searchResult.map((device) => renderDevice(device))}
          </div>
        </div>
      </Collapse>
    </>
  );
};

FleetPageSideBar.propTypes = {
  currentDeviceId: PropTypes.string,
  driverLocations: PropTypes.arrayOf(PropTypes.object),
  onDeviceSelect: PropTypes.func.isRequired,
};

FleetPageSideBar.defaultProps = {
  currentDeviceId: '',
  driverLocations: [],
};

export default FleetPageSideBar;
