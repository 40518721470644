import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import L, { Marker } from 'leaflet';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { FleetDeviceAPIUrl, FleetFormalAPIUrl } from '../../GlobalDefine';
import { GetLoginInfo } from '../../../LoginInfo';
import { FetchAccessToken } from '../../../firebase';
import '../../css/statistic_page/StatisticPageMap.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-rotatedmarker';
import 'leaflet.fullscreen/Control.FullScreen.css';
import 'leaflet.fullscreen';

const customIcon = (data) => {
  let pointColor;

  if (moment().diff(moment(data.created_at), 'minutes') >= 1) {
    pointColor = 'gray';
  } else if (data.idle) {
    pointColor = 'yellow';
  } else if (moment().diff(moment(data.created_at), 'minutes') < 1) {
    pointColor = 'green';
  } else {
    pointColor = 'gray';
  }

  // Use HTML div with a colored circle instead of an image
  return L.divIcon({
    className: 'custom-div-icon', // Custom class for the icon
    html: `<div style="
            background-color: ${pointColor};
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid white;
            "></div>`, // Circle with dynamic color
    iconSize: [20, 20],
    iconAnchor: [10, 10], // Adjust anchor point so it centers properly
    popupAnchor: [0, -15],
  });
};

const StatisticPageMap = ({
  currentDevice,
  fleetStatus, vehicleData, driverData,
}) => {
  // Use driverLocations if provided
  const [deviceList, setDeviceList] = useState([]);
  const mapRef = useRef(null);
  const markerGroupRef = useRef(null);
  const mapInit = useRef(false);
  const componentIsMountedRef = useRef(true);
  const history = useHistory();
  const controller = new AbortController();
  const controllerIdle = new AbortController();
  const initCoordinate = currentDevice?.last_location || [25.0, 121.5];
  const mapInitRef = useRef(false);
  const [completeData, setCompleteData] = useState([]);

  const handleMarkerClick = (device) => {
    const redirectPath = `/vehicle/${device.device_id}`;
    history.push(redirectPath);
  };

  const customPopup = (device) => {
    const driver_image_path = device ? device.driver_image_path : 'no data';
    const url = FleetFormalAPIUrl.slice(0, -1) + driver_image_path;

    return `
      <div style="text-align: center; padding: 10px;">
        <img src="${url}" alt="${device.driver_name}" style="width: 80px; height: 80px; border-radius: 50%; border: 2px solid #ccc; margin-bottom: 10px;">
        <div style="font-size: 14px; color: #333;">
          <strong>Driver:</strong> ${device.driver_name || 'Unknown'}<br>
          <strong>Car:</strong> ${device.car_num || 'Unknown'}<br>
        </div>
      </div>
    `;
  };

  const mergeData = () => fleetStatus.map((fleetItem) => {
    // Find matching vehicle data by device_id
    const matchingVehicle = vehicleData.find(
      (vehicle) => vehicle.device_id === fleetItem.device_id,
    );

    // Find matching driver data by driver_id
    const matchingDriver = driverData.find(
      (driver) => driver.driver_id === fleetItem.driver_id,
    );

    // Return the merged object
    return {
      ...fleetItem,
      car_num: matchingVehicle ? matchingVehicle.car_num : null, // Add car_num from vehicle data
      last_location: matchingVehicle ? matchingVehicle.last_location : null, // Add last_location
      driver_name: matchingDriver ? matchingDriver.name : null, // Add driver name
      driver_image_path: matchingDriver ? matchingDriver.image_path : null, // Add driver image path
    };
  });

  const createMarker = () => {
    const mergedData = mergeData(fleetStatus, vehicleData, driverData);
    setCompleteData(mergeData);

    // Clear all existing markers from the map
    markerGroupRef.current.clearLayers();
    let markerExist = false;
    mergedData.forEach((data) => {
      if (data.last_location) {
        let onLine = false;
        let score = 100;
        const time = moment(data.created_at);
        if (moment().diff(time, 'minutes') < 1) onLine = true;
        if (data.collision) score = 0;
        else if (data.idle || data.geoFencing) score = 70;
        markerExist = true;
        let lat = data.last_location[0];
        let lon = data.last_location[1];
        if (data.latitude !== 0 && data.longitude !== 0) {
          lat = data.latitude;
          lon = data.longitude;
        }

        const marker = new Marker([lat, lon], {
          icon: customIcon(data),
        })
          .bindPopup(customPopup(data))
          .bindTooltip(data.car_num, { permanent: false, direction: 'top' })
          .addTo(markerGroupRef.current)
          .on('click', () => marker.openPopup())
          .on('dblclick', () => handleMarkerClick(data));
      }
    });

    if (markerExist === true) {
      markerGroupRef.current.addTo(mapRef.current);
      if (mapInit.current === false) {
        mapRef.current.flyToBounds(markerGroupRef.current.getBounds(),
          { animation: true, duration: 2 });
        mapInit.current = true;
      }
    }
  };

  const findWarning = () => {
    const fetchPath = `${FleetDeviceAPIUrl}findWarningDevice?time=${moment().format().replace('+', '%2b')}&userId=${GetLoginInfo().userId}`;
    FetchAccessToken()
      .then((accessToken) => {
        axios
          .get(fetchPath, { headers: { Authorization: `${accessToken}` }, signal: controllerIdle.signal })
          .then((response) => {
            if (componentIsMountedRef.current) {
              const { idle, collision, geoFencing } = response.data.data;
              const updatedDeviceList = [...deviceList];

              idle.forEach((idleDevice) => {
                const device = updatedDeviceList.find((d) => d.device_id === idleDevice.device_id);
                if (device) device.idle = true;
              });

              collision.forEach((colDevice) => {
                const device = updatedDeviceList.find((d) => d.device_id === colDevice.device_id);
                if (device) device.collision = true;
              });

              geoFencing.forEach((gfDevice) => {
                const device = updatedDeviceList.find((d) => d.device_id === gfDevice.device_id);
                if (device) device.geoFencing = true;
              });

              setDeviceList(updatedDeviceList);
            }
          })
          .catch((error) => console.log('Error:', error));
      })
      .catch((error) => console.log('Token fetch error:', error));
  };

  const getInfo = () => {
    setDeviceList(fleetStatus);
    findWarning();
    createMarker(); // Use deviceList for markers
  };

  const initMap = () => {
    const streetMap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      detectRetina: true,
      maxZoom: 19,
      maxNativeZoom: 20,
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
    });

    const darkMap = L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png', {
      detectRetina: true,
      maxZoom: 19,
      maxNativeZoom: 20,
      attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; OpenStreetMap contributors',
    });

    const brightMap = L.tileLayer('https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png', {
      detectRetina: true,
      maxZoom: 19,
      maxNativeZoom: 20,
      attribution: '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
    });

    mapRef.current = L.map('map', {
      center: initCoordinate,
      zoom: 17,
      zoomControl: true,
      layers: [streetMap],
    });

    const baseMaps = {
      Streets: streetMap,
      Night: darkMap,
      Bright: brightMap,
    };

    L.control.layers(baseMaps).addTo(mapRef.current);
    L.control.scale({ position: 'bottomright' }).addTo(mapRef.current);
    L.control.fullscreen({ position: 'topright' }).addTo(mapRef.current);
  };

  const initMarkerGroup = () => {
    markerGroupRef.current = L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: true,
      zoomToBoundsOnClick: true,
      iconCreateFunction(cluster) {
        return L.divIcon({
          html: `<b>${cluster.getChildCount()}</b>`,
          className: 'statistic-page-map-custom-marker-cluster',
          iconAnchor: [30, 60],
          iconSize: [50, 50],
        });
      },
    });
  };

  useEffect(() => {
    componentIsMountedRef.current = true;
    if (mapInitRef.current === false) {
      initMap();
      initMarkerGroup();
      mapInitRef.current = true;
    }
    getInfo();

    return () => {
      componentIsMountedRef.current = false;
      controller.abort();
      controllerIdle.abort();
    };
  }, [fleetStatus, vehicleData, driverData]);

  const flyToCurrentDevice = () => {
    const device = completeData.find((item) => item.device_id === currentDevice.device_id);
    if (device && device.last_location) {
      let lat = device.last_location[0];
      let lon = device.last_location[1];
      if (device.latitude !== 0 && device.longitude !== 0) {
        lat = device.latitude;
        lon = device.longitude;
      }
      const latLngBounds = L.latLngBounds([
        [lat - 0.0002, lon - 0.0002],
        [lat + 0.0002, lon + 0.0002],
      ]);
      mapRef.current.flyToBounds(latLngBounds, {
        padding: [50, 50],
        duration: 2,
      });
    }
  };

  useEffect(() => {
    if (currentDevice?.device_id) {
      flyToCurrentDevice();
    }
  }, [currentDevice]);

  return (
    <div className="statistic-page-map container" id="map" />
  );
};

StatisticPageMap.propTypes = {
  currentDevice: PropTypes.shape({
    device_id: PropTypes.string.isRequired,
    last_location: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

export default StatisticPageMap;
