import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import FleetPageSideBar from './fleet_page_components/FleetPageSideBar';
import StatisticPageMap from './statistic_page_componenets/StatisticPageMap';
import '../css/MainLayout.css';
import '../css/Scrollable.css';
import StatStatisticPageFleetCard from './statistic_page_componenets/StatisticPageFleetCard';
import { FleetDeviceAPIUrl, FleetDriverAPIUrl } from '../GlobalDefine';
import { FetchAccessToken } from '../../firebase';
import { GetLoginInfo } from '../../LoginInfo';

function FleetPage() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [currentDevice, setCurrentDevice] = useState({});
  const [fleetStatus, setFleetStatus] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [driverLocations, setDriverLocations] = useState([]);
  const [fetchedDriverIDs, setFetchedDriverIDs] = useState([]); // State to store fetched driver_ids
  const [fetchedDeviceIDs, setFetchedDeviceIDs] = useState([]);

  // Error handling function
  const handleError = (error) => {
    if (error.response) {
      console.log('Error:', error.response.data); // Server response with error message
    } else if (error.request) {
      console.log('Error: No response from the server'); // No response received from the server
    } else {
      console.log('Error:', error.message); // Other errors, like network issues
    }
  };

  const getInfo = async () => {
    const accessToken = await FetchAccessToken();
    let tempFleetstatus = [];

    try {
      // Fetch fleet status
      const response = await axios.get(`${FleetDeviceAPIUrl}fleetStatus?userId=${GetLoginInfo().userId}`, {
        headers: { Authorization: `${accessToken}` },
      });

      if (response.data.data.length > 0) {
        tempFleetstatus = response.data.data;
        setCurrentDevice(tempFleetstatus[0].device_id);
        setFleetStatus(tempFleetstatus);
      }
    } catch (error) {
      handleError(error);
    }

    // Fetch the driver info if driver_id changed
    if (tempFleetstatus.length > 0) {
      try {
        // Extract driver IDs from the fleet status
        // Only select driver_ids that haven't been fetched
        const newDriverIDs = tempFleetstatus
          .map((item) => item.driver_id)
          .filter((id) => !fetchedDriverIDs.includes(id));

        if (newDriverIDs.length > 0) {
          // Fetch new driver info
          const response = await axios.post(
            `${FleetDriverAPIUrl}queryDriverInfo`,
            {
              driver_ids: newDriverIDs, // Send only new driver_ids in the request body
            },
            {
              headers: { Authorization: `${accessToken}` }, // Send headers separately as config
            },
          );

          if (response.data.data.length > 0) {
            setDriverData(response.data.data);
            // Update fetchedDriverIDs state by merging old and new driver_ids
            setFetchedDriverIDs((prev) => [...prev, ...newDriverIDs]);
          }
        }
      } catch (error) {
        handleError(error);
      }

      try {
        // Extract device IDs from the fleet status
        // Only select device_ids that haven't been fetched
        const newDeviceIDs = tempFleetstatus
          .map((item) => item.device_id)
          .filter((id) => !fetchedDeviceIDs.includes(id));

        if (newDeviceIDs.length > 0) {
          // Fetch car numbers for new device_ids
          const carNumResponse = await axios.post(
            `${FleetDeviceAPIUrl}getCarNumByDeviceID`,
            {
              device_ids: newDeviceIDs, // Send only new device_ids in the request body
              language: localStorage.getItem('language') ?? 'en',
            },
            {
              headers: { Authorization: `${accessToken}` }, // Send headers separately as config
            },
          );

          if (carNumResponse.data.data.length > 0) {
            setVehicleData(carNumResponse.data.data);
            // Update fetchedDeviceIDs state by merging old and new device_ids
            setFetchedDeviceIDs((prev) => [...prev, ...newDeviceIDs]);
          }
        }
      } catch (error) {
        handleError(error);
      }
    }
  };

  // Update isSmallScreen on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 800);
    };
    window.addEventListener('resize', handleResize);

    // Call getInfo on initial load
    getInfo();

    // Set interval to call getInfo periodically every 30 seconds
    const intervalId = setInterval(getInfo, 3000); // 30,00 ms = 3 seconds

    return () => {
      window.removeEventListener('resize', handleResize);
      clearInterval(intervalId); // Clear interval when component unmounts
    };
  }, []); // Empty dependency array ensures this runs only on initialization

  const handleDeviceSelect = (device) => {
    setCurrentDevice(device);
  };

  return (
    <div className="scrollable-container fleet">
      <div className="basic-row" style={{ display: 'flex', width: '100%' }}>
        {isSmallScreen ? (
          // Layout for small screens
          <div className="main sidebar-container">
            <StatStatisticPageFleetCard
              fleetStatus={fleetStatus}
              vehicleData={vehicleData}
              driverData={driverData}
            />
            <div className="main sidebar fleet">
              <FleetPageSideBar
                currentDeviceId={currentDevice.device_id}
                driverLocations={driverLocations}
                onDeviceSelect={handleDeviceSelect}
                fleetStatus={fleetStatus}
                vehicleData={vehicleData}
                driverData={driverData}
              />
            </div>
            <br />
            <StatisticPageMap
              driverLocations={driverLocations}
              currentDevice={currentDevice}
              fleetStatus={fleetStatus}
              vehicleData={vehicleData}
              driverData={driverData}
            />
          </div>
        ) : (
          // Layout for larger screens
          <>
            <div className="main sidebar-container">
              <StatStatisticPageFleetCard
                fleetStatus={fleetStatus}
                vehicleData={vehicleData}
                driverData={driverData}
              />
              <div className="main sidebar fleet">
                <FleetPageSideBar
                  currentDeviceId={currentDevice.device_id}
                  driverLocations={driverLocations}
                  onDeviceSelect={handleDeviceSelect}
                  fleetStatus={fleetStatus}
                  vehicleData={vehicleData}
                  driverData={driverData}
                />
              </div>
            </div>
            <StatisticPageMap
              driverLocations={driverLocations}
              currentDevice={currentDevice}
              fleetStatus={fleetStatus}
              vehicleData={vehicleData}
              driverData={driverData}
            />
          </>
        )}
      </div>
    </div>
  );
}

FleetPage.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

FleetPage.defaultProps = {
  match: {},
};

export default FleetPage;
